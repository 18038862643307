import { lighten } from "../../../utils/sassFunction";
export const colors = [
    { name: "primary", title: "Primario", value: `${lighten("#1b1b1b", 10)}` },
    { name: "warning", title: "Amarillo", value: `${lighten('#FFEC43', 10)}` },
    { name: "grey", title: "Gris", value: '#989898' },
    { name: "dark", title: "Oscuro", value: `${lighten('#404040', 10)}` },
];

export const colorsalert = [
    { name: "light", title: "Default", value: '#989898' },
    { name: "success", title: "Suceso", value: '#98BF17' },
    { name: "warning", title: "Alerta", value: '#FFEC43' },
    { name: "danger", title: "Peligro", value: '#B9000B' },
];